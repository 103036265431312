@import '../../styles/colours';
@import '../../../../common/styles/icon-sizes';

.badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    min-width: 26px;
    padding: 4px 8px;
    margin: 2px 0 2px 0;

    background: transparent;
    border: 1px solid rgba($color-primary, .4);

    border-radius: 32px;

    color: $text-secondary;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    vertical-align: middle;

    transform: translateY(-1px);

    + .badge {
        margin-left: 4px;
    }

    @media print {
        border: 1px solid rgba(black, 0.05);
    }

    &[href] {
        text-decoration: none !important;
    }

    &-small {
        font-size: 0.6875rem;
        font-weight: 600;

        mat-icon {
            @extend %icon-size-16;
        }
    }

    &.no-text-transform {
        text-transform: initial;
        font-weight: bold;
    }

    mat-icon {
        //color: white;
        margin: 0;
        @extend %icon-size-16;

        &:first-child {
            margin-right: 4px;
        }
    }

    > mat-menu,
    > .md-button {
        height: 100%;
        margin: -4px -10px -4px 8px;
    }

    > .md-button,
    > mat-menu > .md-button {
        border-radius: 100px;

        &.app-outline {
            border-right: 0;
            border-top: 0;
            border-bottom: 0;
        }
    }

    > mat-menu {
        > .md-button {
            margin: 0;
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    &-green,
    &-success {
        background: darken($color-success, 2%);
        color: white;
        border: 0;
        font-weight: bold;
    }

    &-purple {
        background: $brand-purple;
        color: white;
        border: 0;

        > .mat-icon {
            color: white !important;
        }
    }

    &-primary {
        background: mix(white, $color-primary, 80%);
        color: $text-primary;
    }

    &-blue {
        background: mix(white, $color-blue, 80%);
    }

    &-transparent {
        background: transparent;
    }

    &-warn {
        background: mix(white, $alert-background-warn, 80%);
    }

    &-red,
    &-danger,
    &-error {
        background: mix(#ffffff, $brand-salmon, 80%);
        border-color: $brand-salmon;
        color: $text-primary;
    }

    &-red-solid {
        background: $brand-salmon;
        border-color: $brand-salmon;
        color: white;
        font-weight: bold;
    }

    &-white {
        background: white;
        border-color: white;
        color: $text-primary;
    }

    &-has-icon {
        padding: 8px;
    }

    &-outline {
        background: transparent;
        border: 1px solid $divider;
        color: $text-primary;

        //padding: 8px 14px;

        > mat-menu,
        > .md-button {
            margin: -8px -14px -8px 8px;
        }

        mat-icon {
            color: $text-primary;
        }

        &-big,
        &--big {
            font-size: 0.875rem;
        }

        &-primary {
            border-color: $color-primary;
            color: $color-primary;
        }

        &-purple {
            border-color: $brand-purple;
            color: $brand-purple;
        }
    }

    &-big,
    &--big {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        height: 2.375rem;
    }


    &-beta {
        background: $brand-salmon;
        border: 0;
        color: white;
        padding: 2px 8px;
        font-size: 10px;

        flex-shrink: 0;
    }

    &-circle {
        width: 24px;
        height: 24px;
        padding: 0;

        align-items: center;
        justify-content: center;
        font-weight: bold;
        min-width: 0;
        min-height: 0;
    }
}
