@import '../../../common/styles/colours';

$day-grid-background: white;

$background-tonal: #EEF4F6;

// Bookings
$booking: $color-primary-dark;
$booking-status-confirmed: $color-confirmed;
$booking-status-unconfirmed: $color-danger-dark;
$booking-status-cancelled: $color-danger;
$booking-status-unallocated: #eaeaea;
$booking-status-partially-unallocated: mix($booking-status-unallocated, $booking, 70%);
$booking-status-visited: map-get($light-blue, 'a200');
$booking-status-visit-started: map-get($deep-purple, 'a100');
$booking-status-published: map-get($light-blue, 'a200');

// Grids
$grid-booking: (
    default: var(--color-grid-booking, $booking),
    selected: var(--color-grid-booking-selected, darken($booking, 15%)),
    text: var(--color-grid-booking-text, rgba(white, 0.9)),
);

$grid-booking-confirmed: (
    default: var(--color-grid-booking-confirmed, $booking-status-confirmed),
    selected: var(--color-grid-booking-confirmed-selected, darken($booking-status-confirmed, 15%)),
    text: var(--color-grid-booking-confirmed-text, rgba(white, 0.9)),
);

$grid-booking-cancelled: (
    default: var(--color-grid-booking-cancelled, $booking-status-cancelled),
    selected: var(--color-grid-booking-cancelled-selected, darken($booking-status-cancelled, 15%)),
    text: var(--color-grid-booking-cancelled-text, rgba(white, 0.9)),
);

$grid-booking-unallocated: (
    default: var(--color-grid-booking-unallocated, $booking-status-unallocated),
    selected: var(--color-grid-booking-unallocated-selected, darken($booking-status-unallocated, 15%)),
    text: var(--color-grid-booking-unallocated-text, $text-secondary),
);

$grid-booking-partially-unallocated: (
    default: var(--color-grid-booking-partially-unallocated, $booking-status-partially-unallocated),
    selected:var(--color-grid-booking-partially-unallocated-selected, darken($booking-status-partially-unallocated, 15%)),
    text: var(--color-grid-booking-partially-unallocated-text, $text-secondary),
);

$grid-booking-visit-started: (
    default: var(--color-grid-booking-visit-started, $booking-status-visit-started),
    selected: var(--color-grid-booking-visit-started-selected, darken($booking-status-visit-started, 15%)),
    text: var(--color-grid-booking-visit-started-text, rgba(white, 0.9)),
);

$grid-booking-visited: (
    default: var(--color-grid-booking-visited, $booking-status-visited),
    selected: var(--color-grid-booking-visited-selected, darken($booking-status-visited, 15%)),
    text: var(--color-grid-booking-visited-text, rgba(white, 0.9)),
);

$grid-client-schedule: (
    default: var(--color-grid-client-schedule, #ddd),
    selected: var(--color-grid-client-schedule-selected, darken(#ddd, 15%)),
    text: var(--color-grid-client-schedule-text, var(--colour-grey-variant-dark)),
);

$grid-carer-schedule-available: (
    default: var(--color-grid-carer-schedule-available, $content-background),
    selected: var(--color-grid-carer-schedule-available-selected, darken($content-background, 15%)),
    text: var(--color-grid-carer-schedule-available-text, var(--colour-grey-variant-dark)),
);

$grid-carer-schedule-unavailable: (
    default: var(--color-grid-carer-schedule-unavailable, $accent-100),
    selected: var(--color-grid-carer-schedule-unavailable-selected, darken($accent-200, 15%)),
    text: var(--color-grid-carer-schedule-unavailable-text, var(--colour-grey-variant-dark)),
);

$grid-carer-availability: (
    default: var(--color-grid-carer-available, $content-background),
    selected: var(--color-grid-carer-available-selected, darken($content-background, 15%)),
    text: var(--color-grid-carer-available-text, var(--colour-grey-variant-dark)),
);

$grid-carer-availability-available: (
    default: var(--color-grid-carer-available, $content-background),
    selected: var(--color-grid-carer-available-selected, darken($content-background, 15%)),
    text: var(--color-grid-carer-available-text, var(--colour-grey-variant-dark)),
);

$grid-carer-availability-unavailable: (
    default: var(--color-grid-carer-unavailable, $accent-50),
    selected: var(--color-grid-carer-unavailable-selected, darken($accent-200, 15%)),
    text: var(--color-grid-carer-unavailable-text, var(--colour-grey-variant-dark)),
);

$grid-leave: (
    default: var(--color-grid-leave, $palette-300),
    selected: var(--color-grid-leave-selected, darken($palette-300, 15%)),
    text: var(--color-grid-leave-text, rgba(white, 0.9)),
);

$grid-client-suspension: (
    default: var(--color-grid-client-suspension, $palette-300),
    selected: var(--color-grid-client-suspension-selected, darken($palette-300, 15%)),
    text: var(--color-grid-client-suspension-text, rgba(white, 0.9)),
);

$grid-visit-round: (
    default: var(--color-grid-visit-round, $brand-blue),
    selected: var(--color-grid-visit-round-selected, darken($brand-blue, 15%)),
    text: var(--color-grid-visit-round-text, rgba(white, 0.9)),
);

$grid-carer-schedule-shift: (
    default: var(--color-grid-carer-schedule-shift, $content-background),
    selected: var(--color-grid-carer-schedule-shift-selected, darken($content-background, 15%)),
    text: var(--color-grid-carer-schedule-shift-text, var(--colour-grey-variant-dark)),
);

$grid-shift: (
    default: var(--color-grid-shift, #ff6f00),
    selected: var(--color-grid-shift-selected, darken(#ff6f00, 15%)),
    text: var(--color-grid-shift-text, rgba(white, 0.9)),
);

$grid-shift-attended: (
    default: var(--color-grid-shift-attended, $color-confirmed),
    selected: var(--color-grid-shift-attended-selected, darken($color-confirmed, 15%)),
    text: var(--color-grid-shift-attended-text, rgba(white, 0.9)),
);

$grid-shift-not-attended: (
    default: var(--color-grid-shift-not-attended, lighten(#ff6f00, 15%)),
    selected: var(--color-grid-shift-not-attended-selected, darken(lighten(#ff6f00, 15%), 15%)),
    text: var(--color-grid-shift-not-attended-text, rgba(white, 0.9)),
);

// Alerts
$alert-background-hint: #efefef;
$alert-background-empty: #efefef;
$alert-background-success: $color-success;
$alert-background-danger: $color-danger;
$alert-background-warn: $color-warn;
$alert-background-info: $brand-teal;
$alert-background-default: rgba(darken($content-background, 15%), .2);

// Inputs
$input-background: rgba(black, 0.02);
$input-background-disabled: rgba(black, 0.04);

// Cards

// Inputs
$input-background: rgba(black, 0.02);
$input-background-disabled: rgba(black, 0.04);

$icon-green: $alert-background-success;

// Data Table
$data-table-border-color: $black-12;

