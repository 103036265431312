@import '../../styles/colours';
@import '../../styles/dimens';
@import '../icon-sizes';

.side-nav {
    display: flex;
    flex-direction: column;

    width: $sidebar-expanded-width;
    flex-shrink: 0;

    --side-nav-accent: var(--side-nav-accent-rgb, #{derive-rgb-values-from-hex($brand-purple)});

    .mat-list-base .mat-list-item.mat-list-item-with-avatar,
    .mat-list-base .mat-list-option.mat-list-item-with-avatar {
        height: auto !important;
    }

    .md-subheader {
        position: relative !important;
    }

    @media (max-width: $sidebar-breakpoint) {
        flex-direction: row;
        width: 100%;
        height: auto !important;
        overflow-x: auto;
        padding: 8px 16px;

        > .back-button,
        > h4 {
            display: none !important;
        }

        .mat-nav-list,
        md-list {
            width: auto !important;

            margin: 0 !important;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .mat-list-item {
                width: auto;
            }

            .mat-subheader {
                margin: 0 !important;
                padding-top: 0;
                padding-right: 4px;
                height: auto;
                min-height: 0;

                color: $text-tertiary;
            }
        }
    }

    > .back-button {
        margin: 16px;
        margin-bottom: 80px;
    }

    > h4 {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 13px;
        font-weight: normal;
        text-transform: none;
        color: $text-secondary;
        margin: 0 0 8px;
        padding: 0 16px;

        cursor: pointer;

        outline: none;

        .collapse-icon {
            margin: 0;
            margin-left: auto;
        }
    }

    .mat-nav-list,
    md-list {
        width: 100%;
        padding: 0;
        margin: 0;
        overflow-y: auto;

        @media (min-width: $sidebar-breakpoint + 1px) {
            @import '../../styles/scroll-bars';
        }

        //&:first-child {
        //    margin-top: 136px;
        //}

        &.bottom {
            margin-top: auto;
            height: auto;
        }

        .mat-list-icon,
        .md-list-item-inner md-icon {
            padding: 0 4px 0 0 !important;
            color: rgba(var(--side-nav-accent), .8);
            margin: -1px 0 0 !important;

            @extend %icon-size-16;
        }

        .mat-subheader,
        .md-subheader {
            padding-bottom: 0;
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            text-transform: none;
            background: none;
        }
    }

    .mat-nav-list {

        .mat-list-item {
            height: auto;

            .mat-list-item-content {
                position: relative;
                display: inline-flex;
                font-size: 13px;
                line-height: 36px;
                margin: 8px 8px 8px 0;
                padding: 4px 18px !important;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
                overflow: hidden;
                white-space: nowrap;

                letter-spacing: -0.1px;

                .mat-icon:last-child {
                    margin-left: 4px;
                }

                @media (max-width: $sidebar-breakpoint) {
                    line-height: 34px;
                    border: 2px solid transparent;

                    margin: 0 !important;
                    padding: 0 12px !important;
                    border-radius: 100px;
                }
            }

            &.active {
                .mat-list-item-content {
                    background: rgba(var(--side-nav-accent), 0.04);

                    @media (max-width: $sidebar-breakpoint) {
                        border: 2px solid rgba(var(--side-nav-accent), 0.1);
                    }

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 2px;
                        background: rgb(var(--side-nav-accent));

                        @media (max-width: $sidebar-breakpoint) {
                            display: none;
                            top: auto;
                            right: 0;
                            bottom: 0;

                            width: auto;
                            height: 2px;
                        }
                    }
                }
            }

            &:hover,
            &:focus,
            &:active {
                background: transparent;

                .mat-list-item-content {
                    background: rgba(var(--side-nav-accent), 0.04);
                }
            }
        }
    }

    md-list {
        width: 100%;

        md-list-item {
            height: 58px;
            font-size: 13px;

            @media (max-width: $sidebar-breakpoint) {
                height: auto;
                min-height: auto;
            }

            > .md-button {
                line-height: 36px;
                height: auto;
                margin: 8px 8px 8px 0 !important;
                padding: 4px 18px !important;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;

                min-width: 0;

                letter-spacing: -0.1px;

                white-space: nowrap;

                @media (max-width: $sidebar-breakpoint) {
                    line-height: 34px;
                    border: 2px solid transparent;

                    margin: 0 !important;
                    padding: 0 12px !important;
                    border-radius: 100px;
                }

                > .md-button {
                    min-width: 0;

                    background: transparent;

                    &:hover,
                    &:focus,
                    &:active {
                        background: rgba(var(--side-nav-accent), 0.04);
                    }
                }
            }

            .md-list-item-inner {
                min-height: 0 !important;
            }

            &.active {
                > .md-button {
                    background: rgba(var(--side-nav-accent), 0.04);

                    @media (max-width: $sidebar-breakpoint) {
                        border: 2px solid rgba(var(--side-nav-accent), 0.1);
                    }

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 2px;
                        background: rgb(var(--side-nav-accent));

                        @media (max-width: $sidebar-breakpoint) {
                            display: none;
                            top: auto;
                            right: 0;
                            bottom: 0;

                            width: auto;
                            height: 2px;
                        }
                    }
                }
            }
        }
    }


    @media (min-width: $sidebar-breakpoint + 1px) {
        &.collapsed {
            min-width: 0;
            width: $sidebar-collapsed-width !important;

            h4,
            .mat-list-item-content,
            .md-list-item-inner {
                font-size: 0 !important;
            }

            .notification-badge {
                display: none;
            }

            h4 {
                .collapse-icon {
                    margin: 0;
                    margin-left: 4px;
                    opacity: 1;
                }
            }

            .side-nav-secondary-icon {
                display: none;
            }
        }
    }

    .collapse-icon {
        opacity: 0;
        transform: rotate(90deg);
    }

    .side-nav-secondary-icon {
        @extend %icon-size-16;

        color: $text-secondary;
    }

    &:hover {
        .collapse-icon {
            opacity: 1;
        }
    }

    .notification-badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        min-width: 24px;
        height: 22px;
        padding: 0 4px;
        margin-left: 6px;

        background: rgba(var(--side-nav-accent), .6);
        color: #fff;

        border-radius: 48px;
        font-size: 12px;
        text-align: center;
    }
}
