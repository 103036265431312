@import 'row';

.m-0 { margin: 0 };
.mb-16 { margin-bottom: 16px; }

.no-margin,
.margin-0 {
    margin: 0 !important;
}

.margin-top-1rem {
    margin-top: 1rem;
}

.margin-right-1rem {
    margin-right: 1rem;
}

.margin-1rem {
    margin: 1rem;
}

.margin-16 {
    margin: 16px;
}

.margin-24 {
    margin: 24px;
}
