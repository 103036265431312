@import '../../theming';
@import '../../styles/colours';
@import '../../styles/dimens';
@import 'skinny-card';

mat-card.mat-card {
    padding: 0;

    background: $card-background;
    border-radius: $app-card-border-radius;

    overflow: hidden;

    .mat-card-header {
        nav {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .mat-card-header,
    .mat-card-title-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        min-height: 72px;
        padding: 8px 24px;
        margin: 0;

        border-bottom: 1px solid #efefef;
        background: $card-title-background;

        .mat-card-title,
        .mat-card-subtitle {
            line-height: normal;
        }

        .mat-card-header-text {
            margin: 0;
        }

        .mat-card-title {
            display: flex;
            flex-direction: row;
            margin-bottom: 2px;
            font-size: 20px;
        }

        .mat-card-subtitle {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 14px;
            width: 100%;
        }

        .mat-card-avatar {
            margin-right: 16px;
        }

        .mat-icon.mat-card-avatar {
            width: 24px;
            height: 24px;

            color: $text-secondary;
        }

        .mat-card-header-text {
            flex: 1;
        }

        .app-card-header-actions {
            margin-left: auto;

            .mat-icon-button {
                color: $text-secondary;
                transition: color .1s $swift-ease-in-timing-function;

                &:hover {
                    color: $text-primary;
                }
            }
        }

        .float-right {
            margin-left: auto;
        }

        .mat-card-header-text {
            margin: 0;
        }

        .mat-card-subtitle:not(:first-child) {
            margin: 0 !important;
        }
    }

    .mat-card-content {
        padding: 16px 24px;
        background: $app-card-content-background;


        hr.app-separator {
            margin-left: -24px;
            margin-right: -24px;
        }

        &.no-padding {
            padding: 0;
        }

        &.app-card-loading {
            display: flex;
            align-items: center;
            justify-content: center;

            > app-spinner {
                margin: 16px;
            }
        }
    }

    .mat-card-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        flex-shrink: 0;

        margin: 0;
        padding: 16px 24px;
        border-top: 1px solid #f4f4f4;
        border-top: 1px solid #efefef;
        background: white;

        text-align: right;

        &,
        &:last-child {
            margin: 0;
            padding: 16px 24px;
        }

        .float-left {
            margin-right: auto;
        }

        > mat-paginator {
            margin: -16px -24px;
        }
    }

    &.app-card-inset {
        margin: 1rem 0 0;

        box-shadow: 0 1px 2px 2px rgba($divider, .4),
        0 1px 6px 2px rgba($divider, .4);

        .mat-card-title-group {
            h2 {
                letter-spacing: normal;
            }
        }

        .mat-card-title {
            font-size: 16px;
        }

        .mat-card-subtitle {
            font-size: 14px;
        }
    }

    &.app-pagination-card {
        margin: 1rem 0;
    }

    &.app-actions-card {
        margin: 1rem 0;
    }

    &.nested,
    .mat-card {
        box-shadow: none;
        border: 1px solid $divider;

        &:not(.skinny-card) {
            .mat-card-header {
                min-height: 64px;
            }
        }
    }

    &.danger-card {
        border: 1px solid $color-warn;

        .mat-card-header {
            background-color: $color-warn;
            color: white;

            .mat-card-avatar.mat-icon {
                color: white;
            }
        }

        .action {
            display: flex;

            .title {
                display: block;
                font-size: 14px;
                font-weight: 600;
            }

            .descripton {
                display: block;
            }

            button {
                margin: auto 0 auto auto;
            }
        }
    }
}

mat-card.app-table-card {
    .mat-card-title-group,
    .mat-card-header {
        border: 0 !important;
    }

    .mat-card-header.skinny-header {
        @extend %skinny-header;
    }

    .mat-card-content {
        //margin: 0;
        //padding: 0;

        .data-table {
            thead {
                td, th {
                    background: $card-title-background;
                }
            }
        }

        > app-table,
        > .data-table,
        > .data-table-container {
            margin: -16px -24px !important;
        }

        > .data-table {
            width: calc(100% + 48px);
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

mat-card.mat-card.skinny-card {
    @extend %skinny-card;

    app-input-checkbox {
        margin-left: 8px;
    }
}

mat-card.mat-card.app-nav-list-card {
    .mat-card-content {
        padding: 0;

        .mat-nav-list {
            padding-top: 0;
        }
    }
}
