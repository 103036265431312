@import 'material-colours';

$brand-teal: #2eafa4;
$brand-teal: rgb(46, 175, 164);
$brand-teal-dark: #1d6f68;
$brand-teal-dark: rgb(29, 111, 104);

$brand-salmon: #ee4266;
$brand-purple: rgba(121, 30, 148, 1);
$brand-purple: #791e94;
$brand-yellow: #eac435;
$brand-blue: #54c6eb;

$brand-background-dark: #02534e;
$brand-background-pageturn: #003835;

// Misc
$color-primary: $brand-teal;
$color-primary-dark: #019f95;
$color-primary-dark: #019f95;
$color-success: #64dd17;
$color-success: rgb(100, 221, 23, 1);
$color-success-dark: #1faa00;
$color-confirmed: $color-success;
$color-danger: map-get($red, 'a200');
$color-danger-dark: map-get($red, 'a700');
$color-warn: map-get($deep-orange, 'a400');
$color-warn-dark: map-get($deep-orange, 'a700');
$color-blue: map-get($blue, 'a200');
$color-hint: rgba(black, 0.54);
$color-empty: rgba(black, 0.42);

// Content
$content-background: mix(#fafafa, $color-primary-dark, 97%);
$content-background: #f3f7f7;

// Dividers
$divider: $black-12;
$divider-brand: lighten($brand-teal, 40%);
$divider-dark: $black-38;
$divider-inverse: rgba(white, 0.12);

// Text
$text-primary: $black-87;
$text-primary-inverse: rgba(white, 0.87);

$text-secondary: $black-54;
$text-secondary-inverse: rgba(white, 0.54);

$text-tertiary: rgba(black, 0.38);
$text-tertiary-inverse: rgba(white, 0.38);

$text-disabled: rgba(black, 0.38);
$text-empty: rgba(black, 0.3);
$text-hint: $color-hint;
$text-hint-inverse: rgba(white, 0.7);

$text-inverse-primary: $black-12;
$text-inverse-secondary: $black-38;

// Validation
$validation-red: map-get($red, 'a200');
$validation-warning: map-get($deep-orange, '900');

// Cards
$app-card-content-background: #fafafa;

$card-background: #fafafa;
$card-title-background: white;

// Convert a hex value to comma-delimited rgb values
// In: #000
// Out: 0,0,0
@function derive-rgb-values-from-hex($hex) {
    @return red($hex), green($hex), blue($hex);
}
