@import '../../theming';
@import '../../styles/colours';
@import '../../styles/dimens';

%skinny-header {
    --accent: var(--profile-header-accent-rgb, #{derive-rgb-values-from-hex($brand-purple)});

    min-height: 0;
    height: auto;
    padding: 8px;
    border: 0;

    .mat-card-title {
        margin: 0;
        font-size: 14px;
        font-weight: 600;

        justify-content: space-between;
    }

    .mat-card-avatar.mat-icon {
        color: rgba(var(--accent), 1);
    }

    // Make the avatar the same size as icons
    .mat-card-avatar {
        height: 24px;
        width: 24px;
    }

    nav,
    .float-right {
        .mat-icon-button {
            margin: -8px;
        }
    }
}

%skinny-card {
    background: white;

    .mat-card-header {
        @extend %skinny-header;
    }

    .mat-card-content {
        position: relative;

        margin: 0;
        padding: 8px;

        background: white;
        font-size: 13px;

        overflow-y: auto;
        will-change: transform;

        hr.app-separator {
            margin-left: -8px;
            margin-right: -8px;
        }

        ::ng-deep {
            .spinner-container {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .empty {
                padding: 8px 16px;
                text-align: center;
                color: $text-hint;
            }

            > .mat-list-base {
                padding: 0;
                margin: -4px;

                .mat-list-item {
                    height: auto;
                    border-radius: 6px;
                    overflow: hidden;
                }

                .mat-list-item-content {
                    padding: 8px;
                }

                .mat-list-item-content {
                    font-size: 13px;

                    p {
                        margin: 0;
                    }
                }

                .mat-list-icon {
                    margin-left: -8px;
                }
            }
        }
    }

    .mat-card-actions,
    .mat-card-actions:last-child {
        padding: 8px !important;
        margin-top: 8px;
        border-top: 0;

        font-size: 13px;

        &:empty {
            display: none;
        }
    }

    &.app-table-card {
        .mat-card-content {
            > .data-table,
            > .data-table-container {
                width: 100% !important;
                margin: -8px !important;
            }
        }
    }

    .indent-header-icon {
        padding-left: 8px + 24px + 16px;
    }

    &.skinny-card-red {
        background: $brand-salmon;
        color: white;

        * {
            color: white !important;
        }

        .mat-card-header,
        .mat-card-content,
        .mat-card-actions {
            background: $brand-salmon;
        }

        .mat-card-avatar.mat-icon {
            color: white;
        }
    }
}
