$secondary-colour: #87189d;
$secondary-colour: #87189d;
$secondary-colour-dark: #3d0b47;
$secondary-colour-light: #f3e8f5;

$warning-background-colour: #fff7e8;
$warning-colour: #ffab1a;
$warning-font-colour: #232931;

$error-background-colour: #fbe7e8;
$error-colour: #D4111B;
$error-font-colour: #5f080c;

.app-snackbar-success {
}
.app-snackbar-action-required-warning{
    color: $warning-font-colour;
    border-left: 4px solid $warning-colour;
    background-color: $warning-background-colour;
}

.app-snackbar-action-required-error{
    color: $error-font-colour;
    border-left: 4px solid $error-colour;
    background-color: $error-background-colour;
}

.app-snackbar-error {
    background: $color-danger-dark;

    &,
    .mat-simple-snackbar-action {
        color: rgba(white, 0.9);
    }
}

.app-snackbar-error {
    background: $color-danger-dark;

    &,
    .mat-simple-snackbar-action {
        color: rgba(white, 0.9);
    }
}

.mat-simple-snackbar {
    &,
    .mat-simple-snackbar-action {
        color: rgba(white, 0.9);
    }
}
