@import '../../../common/styles/dimens';

$app-card-border-radius: 4px;
$input-border-radius: 3px;

$dialog-content-padding-horizontal: 24px;
$dialog-content-padding-vertical: 16px;
$dialog-actions-padding-horizontal: 24px;
$dialog-content-padding-vertical: 16px;

$navigation-width: 240px;
$navigation-item-height: 56px;

$app-page-header-height: 84px;
