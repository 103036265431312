@import '@mastechnicaegroup/carelinelive-ui/styles/variables/text-styles';
@import '../../styles/colours';
@import '../../styles/dimens';
@import '../../styles/fonts';

.app-form-field,
.mat-form-field {
    display: block;
}

.app-form-field {
    margin: 8px 0;

    .mat-form-field {
        display: block;
    }

    > .mat-checkbox {
        display: block;
        margin: 8px 0 24px;
    }

    .clear-button {
        margin-top: -2px;
        color: $text-secondary;
        transform: translateY(-2px);
    }

    &.has-subscript {
        margin-bottom: 16px;
    }

    &:not(.has-subscript) {
        .mat-form-field-appearance-standard {
            .mat-form-field-underline {
                bottom: 0;
            }
        }

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    }

    .mat-form-field-label-wrapper {
        .mat-icon {
            vertical-align: middle;
        }
    }
}

.mat-checkbox {
    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-radius: 100px;
    }
}

.mat-pseudo-checkbox {
    border-radius: 100px !important;
}

.mat-form-field {
    &.mat-form-field-appearance-fill {
        .mat-form-field-flex {
            background-color: $input-background;
        }

        &.mat-form-field-disabled .mat-form-field-flex {
            background-color: $input-background-disabled;
        }
    }
}

.mat-form-field-appearance-fill {
    .mat-form-field-flex {
        border-radius: $input-border-radius !important;
    }

    .mat-form-field-underline::before,
    .mat-form-field-ripple {
        background-color: rgba($brand-teal, 0.87) !important;
    }
}

.form-section-header-base {
    //font-size: 14px;
    //line-height: 16px;
    //font-weight: 600 !important;
    //
    //color: $text-primary;

    @extend %text-title-small;
}

.mat-subheader,
.form-section-header,
.form-section-title {
    @extend .form-section-header-base;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    gap: 4px;

    margin: 24px 0 16px 0;
    //padding-bottom: 16px;

    > code {
        font-weight: normal;
    }

    .float-right {
        margin-left: auto;
    }

    .form-section-header-item {
        margin: 0;
    }

    &.no-border {
        border-bottom: 0;
    }

    > small {
        display: block;
        margin-left: auto;

        color: $text-tertiary;
    }

    > p {
        width: 100%;
        text-transform: none;
        font-weight: normal;
        font-size: 14px;
    }

    app-input-checkbox {
        md-input-container {
            margin: 0;
        }
    }

    > app-toggle-button-group {
        padding: 0;
    }

    > .mat-icon:first-child {
        margin-right: 16px;
    }

    .mat-menu-content & {
        margin: 0;
        padding: 12px 16px;
        border-bottom: 2px solid $content-background;
        user-select: none;
        pointer-events: none;
    }

    > nav {
        margin-left: auto;
    }
}

h2,
h3 {
    &.form-section-header,
    &.form-section-title {
        font-size: 1rem;
    }
}

.form-section-subheader,
h5.form-section-header,
h6.form-section-header {
    font-size: 0.75rem;
    border-bottom: 0;
    margin-bottom: 0.5rem;
    padding-bottom: 0;
}

.form-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;

    &-align-center {
        align-items: center;
    }

    &-justify-center {
        justify-content: center;
    }

    &-center {
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 480px) {
        flex-direction: column;

        &-align-center {
            align-items: start;
            justify-content: center;
        }

        &-justify-center {
            align-items: center;
            justify-content: start;
        }
    }

    > .form-row-column {
    }

    .flex {
        flex: 1;
    }

    .flex-10 {
        flex: 1 1 10%;
        max-width: 10%;
    }

    .flex-15 {
        flex: 1 1 15%;
        max-width: 15%;
    }

    .flex-20 {
        flex: 1 1 20%;
        max-width: 20%;
    }

    .flex-25 {
        flex: 1 1 25%;
        max-width: 25%;
    }

    .flex-30 {
        flex: 1 1 30%;
        max-width: 30%;
    }

    .flex-33 {
        flex: 1 1 33.33%;
        max-width: 33.33%;
    }

    .flex-40 {
        flex: 1 1 40%;
        max-width: 40%;
    }

    .flex-45 {
        flex: 1 1 45%;
        max-width: 45%;
    }

    .flex-50 {
        flex: 1 1 50%;
        max-width: 50%;
    }

    .flex-55 {
        flex: 1 1 55%;
        max-width: 55%;
    }

    .flex-66 {
        flex: 1 1 66%;
        max-width: 66%;
    }

    .flex-75 {
        flex: 1 1 75%;
        max-width: 75%;
    }

    > app-data-field,
    .data-field {
        margin-top: 0;
        margin-bottom: 0;
    }

    + .form-row {
        margin-top: 1rem;
    }

    .form-row-prefix,
    .form-row-suffix {
        margin-top: 30px;
    }

    .form-row-prefix {
    }

    .form-row-label {
        padding: 0 1rem;
        text-align: right;
    }

    .float-right {
        margin-left: auto;
    }

    &-align-top {
        align-items: flex-start;
    }

    &.form-actions {

        button {
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.form-row:not(.form-row-align-center) {
    > .form-row-label {
        padding-top: 28px;
        color: $palette-600;
        font-size: 1rem;
    }
}

.form-hint {
    font-size: 12px;
    color: $text-hint;
}

.form-group {
    display: flex;
    flex-direction: row;

    padding: 0;

    align-items: center;

    &:last-of-type,
    &:last-child {
        border-bottom: 0;
    }

    .form-group-content,
    .form-group-input,
    .form-group-comments,
    .form-group-label {
        flex-shrink: 0;
    }

    .form-group-comments,
    .form-group-label {
        width: 33%;
        margin-right: 24px;

        label {
            font-weight: normal;
        }

        ul,
        li,
        p {
            color: $text-hint;
            font-size: 13px;
        }
    }

    .form-group-comments {
        ul {
            padding: 0;
        }

        li {
            margin-bottom: 8px;
            padding-bottom: 8px;
            list-style: none;
            border-bottom: 1px solid $divider;

            > .label {
                color: $text-primary;
            }
        }
    }

    > .form-group-content,
    > .form-group-input,
    > .app-form-field {
        min-width: 33%;
    }

    .form-group-content,
    .form-group-input {
        > .app-form-field {
            display: inline-flex;
        }

        .suffix {
            font-size: 14px;
        }
    }
}

.mat-autocomplete-panel {
    .mat-option-text {
        font-size: 13px;
    }
}

.app-form-select-search-container {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;

    > .mat-icon {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 16px;
    }

    input {
        flex: 1;
        border: none;
        outline: none;
        padding: 0 16px 0 0;

        font-family: $font-family;
    }
}

