.app-panel.mat-expansion-panel {
    box-shadow: 0 0 0 2px transparent;
    border-radius: 12px;

    background: #EEF4F6;

    transition: background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

    &.mat-expanded {
        background: lighten(#EEF4F6, 3%);
        box-shadow: 0 0 0 2px #EEF4F6;
    }
}
