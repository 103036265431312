.row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    &.align-top {
        align-items: flex-start;
    }

    &.align-right {
        justify-content: flex-end;
    }
}
