@import '../../styles/colours';
@import '../../../../common/styles/icon-sizes';

.mat-button,
.mat-flat-button,
.mat-raised-button,
.mat-stroked-button {
    min-width: 88px !important;
    padding-left: 16px;
    padding-right: 16px;

    font-size: 13px;
    text-transform: uppercase;
    border-radius: 100px !important;

    &.skinny {
        line-height: 24px;
    }

    .mat-button-wrapper {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        vertical-align: middle;
    }

    &:not(.mat-icon-button) {
        > .mat-button-wrapper {
            .mat-icon {
                width: 18px;
                height: 18px;
                font-size: 18px;

                vertical-align: middle;
            }

            > .mat-icon {
                margin-right: 4px;
                margin-left: 4px;

                &:first-child {
                    margin-right: 8px;
                }

                &:last-child {
                    margin-left: 8px;
                }
            }
        }
    }
}

.mat-icon-button {
    .mat-icon {
        color: $text-secondary;
    }

    &.mat-button-disabled {
        .mat-icon {
            color: rgba(0, 0, 0, 0.26) !important;
        }
    }

    &.size-16 {
        .mat-button-wrapper {
            line-height: 16px;
        }

        .mat-icon {
            @extend %icon-size-16;
        }
    }

    &.size-20 {
        .mat-button-wrapper {
            line-height: 20px;
        }

        .mat-icon {
            @extend %icon-size-20;
        }
    }

    &.size-32 {
        .mat-button-wrapper {
            line-height: 32px;
        }

        .mat-icon {
            @extend %icon-size-32;
        }
    }
}

.mat-primary-button {
    background: $color-primary;
    color: white;

    &:hover {
        background: $color-primary-dark;
    }
}

.mat-raised-button {
    .mat-button-wrapper {
        //margin-top: -4px;
    }
}

.mat-button-toggle-group {
    &:not(.tight) {
        gap: 8px;
        border-radius: initial !important;
        border: none;
        flex-wrap: wrap;

        .mat-button-toggle {
            border-radius: 100px;
            border: none !important;
            background: rgba(black, 0.04);
            overflow: hidden;

            cll-icon,
            .mat-icon {
                display: inline-block;
                opacity: 0;

                line-height: 16px !important;
                font-size: 16px !important;
                height: 16px !important;
                min-height: 16px !important;

                // Set width to 0 so we can animate it in
                width: 0 !important;
                min-width: 0 !important;

                transform: scale(0);
                transition: transform 0.3s, width 0.3s, min-width 0.3s, opacity 0.3s;
            }

            &.mat-button-toggle-checked {
                background: $brand-purple;
                color: white;

                .mat-icon {
                    transform: scale(1);
                    margin-right: 4px;
                    width: 16px !important;
                    min-width: 16px !important;
                    opacity: 1;
                }
            }

            .mat-button-toggle-label-content {
                padding: 0 16px !important;
                line-height: 36px !important;
                text-transform: none;
            }
        }
    }

    &.tight {
        border-radius: 20px !important;
        .mat-button-toggle {
            border-radius: 0px !important;
            &.mat-button-toggle-checked {
                background: #EDF7FF !important;
                color: #204d71 !important;

                .mat-icon {
                    font-size: 20px;
                    opacity: 1;
                }
            }
            .mat-button-toggle-label-content {
                padding: 10px 20px !important;
                line-height: 20px !important;
                height: 40px !important;
                text-transform: none;
            }
        }
    }
}
