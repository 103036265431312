$font-family: "'Nunito Sans', 'Nunito', 'Open Sans', sans-serif";

@import '../../../shared/theme';

@import '../../../node_modules/@mastechnicaegroup/carelinelive-ui/styles';

* {
    box-sizing: border-box;
}

:root {
    font-size: 16px;
}

html,
body {
    position: relative;
    height: 100%;
    margin: 0;
}

body {
    font-smooth: auto;
    -webkit-font-smoothing: antialiased;
    background: #f3f7f7;

    font-family: 'Nunito Sans', 'Nunito', 'Open Sans', sans-serif;
}

app-root-auth {
    @import '../../../shared/common/buttons/buttons';
    @import '../../../shared/common/cards/cards';
    @import '../../../shared/common/forms/forms';

    a[href] {
        text-decoration: none;
        color: $text-primary;
    }
}
