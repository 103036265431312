@import '../../styles/colours';
@import '../icon-sizes';

$vertical-breakpoint: 900px;

header.profile-header {
    display: flex;
    flex-direction: row;
    padding: 24px 24px 16px;
    background: $content-background;
    font-size: 14px;

    --profile-header-accent: var(--profile-header-accent-rgb, #{derive-rgb-values-from-hex($brand-teal)});

    @media (max-width: 800px) {
        flex-wrap: wrap;
    }

    .profile-name {
        display: flex;
        flex-direction: row;
        align-items: center;

        h1 {
            display: inline-block;
        }

        > app-avatar,
        > .avatar {
            width: 56px;
            height: 56px;
            margin-right: 16px;
            flex-shrink: 0;

            box-shadow:
                0 0 0 2px $content-background,
                0 0 0 4px rgba(var(--profile-header-accent), .6);

            @media (max-height: $vertical-breakpoint) {
                width: 32px;
                height: 32px;
            }
        }
    }

    h1 {
        margin: 0;

        font-size: 18px;
        font-weight: normal;
    }

    .profile-header-content {
        address {
            font-style: normal;
            color: $text-secondary;
        }

        app-meta-list {
            margin-left: 48px;
        }

        dl {
            font-size: 13px;
            margin-left: 48px;

            @media (max-height: $vertical-breakpoint) {
                margin-left: 20px;
            }

            > div {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .mat-icon,
            md-icon {
                color: $text-secondary;
                margin-right: 8px;

                @extend %icon-size-16;
            }

            dt, dd {
                margin: 0;
                padding: 0;
            }

            p {
                margin: 0;
            }
        }
    }

    nav {
        flex-shrink: 0;
        margin-left: auto;
    }
}
