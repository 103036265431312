@import '@angular/material/theming';
@import '../../styles/colours';
@import '../../styles/dimens';
@import '../../styles/fonts';

.data-table {
    .align-right,
    tr.align-right td,
    tr.align-right th {
        text-align: right !important;
    }

    td.no-vertical-padding,
    th.no-vertical-padding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    td.no-horizontal-padding,
    th.no-horizontal-padding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .align-middle {
        vertical-align: middle;
    }

    .align-center {
        text-align: center !important;
    }

    .data-table__actions {
        .md-icon-button {
            margin: 0;
        }
    }

    &.data-table-fixed {
        table-layout: fixed;
    }
}

.data-table {
    &.data-table-card {
        background: white;
        border-radius: $app-card-border-radius;

        @include mat-elevation(2);
    }
}

div.data-table {
    display: flex;
    flex-direction: column;
    min-width: 100%;

    div.data-table-head,
    div.data-table-body {
        min-width: 100%;
    }

    div.data-table-head {
        font-size: 12px;
        color: $text-secondary;

        .cell {
            height: 48px;
            font-weight: 600;
        }
    }

    div.data-table-body {
        font-size: 13px;
    }

    div.data-table-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 100%;
        min-height: 48px;
        padding: 0 24px;

        flex: 1;

        border-bottom: 1px solid $data-table-border-color;

        &.no-border {
            border-bottom: 0;
        }

        .cell-group {
            display: flex;
            flex-direction: row;
            align-items: center;

            &.column {
                flex-direction: column;
            }

            &.align-right {
                justify-content: flex-end;
            }
        }

        > .cell-group > .cell,
        > .cell {
            padding: 8px;
        }

        .pull-right {
            margin-left: auto;
        }
    }

    .cell {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        flex-shrink: 0;
        text-align: left;

        &.column {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        &.border-left {
            border-left: 1px solid $data-table-border-color;
        }

        &.numeric {
            text-align: right;
        }

        &.align-center {
            justify-content: center;
        }
    }

    div.data-table-empty {
        display: flex;
        justify-content: center;

        min-height: 96px;
        padding: 1rem;

        font-size: 14px;
        cursor: default;
        text-align: center;

        > p:first-child {
            margin: 0;
        }
    }
}

.data-table-container {
    position: relative;
    //min-height: 128px;

    overflow: auto;

    .data-table {
        height: 100%;
    }

    .spinner {
        position: absolute;
        top: 48px;
        left: 50%;
        margin-top: 16px; // Push it below thead
        margin-left: -25px;
        text-align: center;

        display: none;
    }

    &.loading {
        tbody {
            opacity: 0.2;

            pointer-events: none;
            user-select: none;
        }

        .spinner {
            display: block;
        }
    }
}

.data-table-loading-container {
    text-align: center;
    margin: 1rem;
}

table.data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-color: $data-table-border-color;

    tr > th {
        z-index: 10;
    }

    tr > th,
    tr > td {
        text-align: left;
        border-bottom: 1px solid $data-table-border-color;
        padding: 8px;

        &.sticky-left {
            background: linear-gradient(to left, transparent, white 25%);
        }

        &.sticky-right {
            background: linear-gradient(to right, transparent, white 25%);
        }

        &:first-child:not(.normal-padding) {
            padding-left: 24px;
            padding-right: 24px;

            &.app-data-table-checkbox-cell {
                > label {
                    padding-left: 24px;
                    padding-right: 24px;
                }
            }
        }

        &.app-data-table-checkbox-cell {
            padding: 0;

            > label {
                height: 100%;
                padding: 0 8px;
            }
        }

        &.numeric {
            text-align: right;
        }

        &.icon {
            padding: 0 4px;

            &.icon--green {
                .mat-icon {
                    color: $icon-green;
                }
            }

            &.icon--grey {
                .mat-icon {
                    color: $text-secondary;
                }
            }
        }

        &.actions-card {
            text-align: right;

            .md-button {
                margin: 0;
            }
        }

        &.border-left {
            border-left: 1px solid $data-table-border-color;
        }

        p {
            margin: 0;
        }
    }

    thead {
        font-size: 12px;
        color: $text-secondary;

        td,
        th {
            height: 48px;
            font-weight: normal;
            //background: white;
        }
    }

    tbody {
        font-size: 12px;

        td, th {
            &.mat-cell {
                font-size: 12px;
            }
        }

        tr {
            outline: none;

            &.data-table-empty td,
            &.data-table-loading td {
                min-height: 96px;
                padding-top: 16px;
                padding-bottom: 16px;

                cursor: default;
                text-align: center;

                > p:first-child {
                    margin: 0;
                }
            }

            &.data-table-loading app-loading {
                height: 64px;
            }

            &.data-table-empty {
                .md-button {
                    margin: 1rem;
                }
            }

            &.clickable {
                &, td, th {
                    cursor: pointer;
                }
            }
        }

        &:last-child {
            tr:last-child {
                td,
                th {
                    border-bottom: 0;
                }
            }
        }

        tr[ng-click] td {
            cursor: pointer;
        }

        td {
            height: 48px;
            color: $text-primary;
            cursor: default;

            &.selected {
                background: #f5f5f5;
            }
        }
    }

    .mat-icon {
        color: $text-secondary;
    }

    tfoot {
        font-size: 12px;
        color: $black-54;

        tr {
            height: 56px;
        }

        &:last-child {
            tr:last-child {
                td,
                th {
                    border-bottom: 0;
                }
            }
        }
    }

    &.data-table--small {
        thead {
            td,
            th {
                height: 24px;
            }
        }

        tfoot,
        tbody {
            td {
                height: 24px;
            }
        }
    }
}

table.data-table--no-hover > tbody > tr:hover {
    > td,
    > th {
        background: inherit !important;
    }
}

tr.border-top {
    td,
    th {
        border-top: 1px solid $data-table-border-color;
    }
}

.app-data-table-card-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .app-table-card {
        display: flex;
        flex-direction: column;
        max-height: 100%;

        mat-card-actions {
            padding: 0;
        }

        > mat-card-content {
            display: flex;
            flex-direction: column;

            overflow: hidden;

            .data-table-container {
                overflow: auto;
            }

            .data-table {
                thead {
                    th {
                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}

.app-data-table-checkbox-cell {
    outline: none;

    > label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
