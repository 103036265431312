@import '../../../shared/styles/colours';
@import '../../../../common/styles/icon-sizes';

.mat-icon {
    vertical-align: middle;

    &.icon-red {
        color: $color-danger !important;
    }
}

.mat-icon {
    &.icon-secondary {
        color: $text-secondary;
    }
    &.icon-hint {
        color: $color-hint;
    }

    &.icon-light-grey {
        color: #aaaaaa;
    }

    &.icon-success,
    &.icon-green {
        color: $color-success !important;
    }

    &.icon-error,
    &.icon-red {
        color: $brand-salmon !important;
    }

    &.icon-warning,
    &.icon-yellow {
        color: $brand-yellow !important;
    }

    &.icon-purple {
        color: $brand-purple !important;
    }

    &.size-8 {
        @extend %icon-size-8;
    }

    &.size-12 {
        @extend %icon-size-12;
    }

    &.size-14 {
        @extend %icon-size-14;
    }

    &.size-16 {
        @extend %icon-size-16;
    }

    &.size-18 {
        @extend %icon-size-18;
    }

    &.size-20 {
        @extend %icon-size-20;
    }

    &.size-24 {
        @extend %icon-size-24;
    }

    &.size-32 {
        @extend %icon-size-32;
    }

    &.size-48 {
        @extend %icon-size-48;
    }

    &[ng-click] {
        cursor: pointer;
    }
}

.mat-form-field {
    .mat-icon[matprefix] {
        margin-right: 4px;
        color: $text-secondary;
    }
}

figure.icon-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 0 1rem 0 0;
    padding: 0;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.03);
}
