@import '../styles/dimens';

.cdk-overlay-pane {
    //flex: 1;
    //max-width: 90vw !important;
}

mat-dialog-container.mat-dialog-container {
    flex: 1;
    min-width: 240px;
    width: 100%;

    padding: 0;
    margin: 24px;
    border-radius: 12px;

    @media (max-width: 800px) {
        margin: 8px;
    }

    mat-dialog-content.mat-dialog-content {
        background: #fafafa;
        padding: $dialog-content-padding-vertical $dialog-content-padding-horizontal;
        margin: 0;

        > app-spinner {
            margin: 0 auto;
        }

        hr.app-separator {
            + .form-group {
                margin-top: -16px;
            }
        }

        .form-group,
        hr.app-separator {
            margin-left: -24px;
            margin-right: -24px;
        }

        .form-group {
            padding-left: $dialog-content-padding-horizontal;
            padding-right: $dialog-content-padding-horizontal;
        }

        > .form-section-header:first-child {
            margin-top: 0;
        }
    }

    mat-dialog-actions.mat-dialog-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        margin: 0;

        flex-shrink: 0;
        padding: $dialog-content-padding-vertical $dialog-actions-padding-horizontal;
        border-top: 1px solid #f4f4f4;

        &:empty {
            display: none;
        }

        .float-left {
            margin-right: auto !important;
        }

        button {
            margin-left: 8px;
        }
    }
}
