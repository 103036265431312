@import '../../styles/colours';
@import '../../styles/dimens';

.mat-list-base {
    .mat-list-item {
        font-size: 14px;
    }

    app-avatar.mat-list-avatar,
    .mat-icon.mat-list-avatar,
    .mat-icon.mat-list-icon {
        width: 24px !important;
        height: 24px !important;
        font-size: 24px;
        color: $text-secondary;
        padding: 0;
        margin: 0 8px 0 0;

    }

    .app-list-chevron {
        margin-left: auto;
        color: $text-secondary;
    }

    .mat-list-item:hover {
        .app-list-chevron {
            color: $brand-purple;
        }
    }

    &.rounded {
        .mat-list-item {
            border-radius: 6px;
        }
    }
}

.app-card-list {
    margin-top: 16px;

    .mat-list-item {
        border: 1px solid $divider;
        border-radius: $app-card-border-radius;
        height: auto !important;

        margin: 8px 0;

        .mat-list-item-content {
            min-height: 56px;
            padding: 8px 16px !important;
        }
    }
}

.mat-dialog-content > .app-card-list {
    .mat-subheader {
        margin-top: 24px;
        border-top: 1px solid $divider;

        padding-left: $dialog-content-padding-horizontal;
        padding-right: $dialog-content-padding-horizontal;

        margin-left: -$dialog-content-padding-horizontal;
        margin-right: -$dialog-content-padding-horizontal;
    }
}
