@mixin space-x($amount) {
    > :not([hidden]):not(:empty) ~ :not([hidden]):not(:empty) {
        margin-left: #{$amount}px;
    }
}

@mixin space-y($amount) {
    > :not([hidden]):not(:empty) ~ :not([hidden]):not(:empty) {
        margin-top: #{$amount}px;
    }
}
