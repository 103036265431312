@import '../styles/colours';
@import '../styles/dimens';
@import 'text';
@import 'headings';
@import 'badges/badges';
@import 'buttons/buttons';
@import 'cards/cards';
@import 'tabs/tabs';
@import 'icons/icons';
@import 'forms/forms';
@import 'lists/lists';
@import 'layout/layout';
@import 'tables/data-table';
@import 'spinners/spinners';
@import 'tooltips/tooltips';
@import 'panels/panels';
@import 'popover/popover';
@import '../../../common/styles/profiles/profile-header';
@import '../../../common/styles/side-nav/side-nav';

// Misc

a[href]:not(.mat-button-base):not(.mat-tab-link):not(.mat-list-item):not(.mat-menu-item):not(.cll-button--default):not(.cll-button--filled):not(.cll-button--tonal):not([cll-icon-button]):not(.cll-link),
.link {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;

    &.dotted {
        text-decoration: underline dotted;
    }
}

.dotted {
    text-decoration: underline dotted;
}

button.link {
    display: inline-block;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    font-family: inherit;
    font-size: inherit;
}

a.no-underline {
    text-decoration: none !important;
    cursor: pointer;
    color: inherit;
}

.hover-underline {
    text-decoration: none !important;
    cursor: pointer;
    color: inherit;

    &:hover {
        text-decoration: underline !important;
    }
}

.hover-dotted {
    &:hover {
        text-decoration: underline dotted !important;
    }
}

hr {
    display: block;
    height: 1px;
    margin: 24px 0;

    background: $divider;
    border: 0;
}

.align-center {
    text-align: center;
}

.mat-expansion-panel-body hr.app-separator {
    margin-left: -24px;
    margin-right: -24px;
}

.hint {
    color: var(--colour-grey-variant-normal);

    &.app-danger {
        color: $color-danger;
    }
}

.vertical-separator {
    width: 1px;
    height: 16px;
    flex-shrink: 0;
    background: rgba(black, .5);
    margin: auto 4px;
}

.sticky-bottom {
    position: sticky !important;
    bottom: 0;
    z-index: 100;
}

.sticky-left {
    position: sticky !important;
    left: 0;
    z-index: 100;
}

.sticky-right {
    position: sticky !important;
    right: 0;
    z-index: 100;
}

.sticky-top {
    position: sticky !important;
    top: 0;
    z-index: 100;
}

.block {
    display: block;
}

.bold {
    font-weight: bold;
}

.mat-menu-panel {
    //align-self: flex-end;
}

.app-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    margin: 0;
    padding: 0;

    h1,
    h2 {
        font-size: 21px;
        font-weight: 300;
    }

    h1,
    h2,
    .title {
        margin: 0;
        flex: 1;
    }

    nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;

        flex-shrink: 0;
        margin-left: auto;

        .mat-button-base,
        .mat-form-field {
            margin-left: 16px;
        }
    }
}

.app-page-header {
    @extend .app-header;
    position: sticky;
    top: 0;

    min-height: $app-page-header-height;

    background: $content-background;
    z-index: 105;

    > .mat-icon-button:first-child {
        margin-left: -24px;
    }

    @media (max-height: 900px) {
        position: static;
    }
}

.app-filter-select {
    min-width: 196px;

    .mat-form-field-infix {
        border-top: 0.5em solid transparent;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-select-arrow {
        position: relative;
        border: 0 !important;
        margin-right: 12px;

        &:after {
            position: absolute;
            top: 6px;
            left: 0;
            font-family: 'Material Icons';
            content: 'filter_list';
            font-size: 18px;
            transform: translate(-50%, -50%);
        }
    }
}

mat-card.mat-card.app-pagination-card {
    position: sticky;
    bottom: 0;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding: 0 16px;

    background: white;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.float-left {
    margin-right: auto !important;
}

.float-right {
    margin-left: auto !important;
}

.mat-select-panel {
    .mat-option,
    .mat-optgroup-label {
        height: auto !important;
    }

    .mat-option {
        padding-top: 12px;
        padding-bottom: 12px;
        min-height: 3em;

        &.has-avatar {
            padding-left: 8px;
        }
    }

    .mat-optgroup-label {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 13px;
        color: $text-secondary;
        line-height: normal;
    }

    .mat-option-text {
        line-height: normal;
    }

    .mat-optgroup-label {
        line-height: normal !important;
        padding-top: 8px;
    }
}

.mat-select,
.mat-select-panel {
    .mat-option-text {
        display: inline-flex;
        align-items: center;
        max-width: 100%;

        > div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .mat-option-text,
    .mat-optgroup-label {
        .only {
            margin-left: auto;
            flex-shrink: 0;
            padding-left: 4px;
            text-transform: uppercase;
            font-size: 10px;
            color: $text-secondary;

            &:hover {
                color: $text-primary;
                text-decoration: underline;
            }
        }
    }

    .mat-option-description {
        display: block;
        color: $color-hint;
    }

    .select-all-option {
        .mat-icon {
            margin-right: 8px;
            @extend %icon-size-16;
        }
    }
}

.mat-select {
    .mat-option-description {
        white-space: nowrap;
    }
}

.mat-select-panel {
    .mat-option-description {
        white-space: pre-wrap;
    }
}

.app-popover-panel {
    // The overlay reference updates the pointer-events style property directly on the HTMLElement
    // depending on the state of the overlay. For tooltips the overlay panel should never enable
    // pointer events. To overwrite the inline CSS from the overlay reference `!important` is needed.
    pointer-events: none !important;

    z-index: 1000;
}

kbd {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.1em 0.5em;
    margin: 0 0.2em;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    background-color: #f7f7f7;
    color: $text-primary;
}

.spinner-container {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
}

.indented-aside {
    position: relative;
    //border-left: 2px solid rgba($brand-purple, .6);
    padding: 16px;
    margin-left: 14px;

    > p:first-child,
    > h4:first-child {
        margin-top: 0;
    }

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2px;
        background: rgba($brand-purple, .6);
        border-radius: 2px;
    }
}

.readable-width {
    max-width: 70ch;
}

.grid-container {
    container-type: inline-size;
}

.grid-auto-128 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
    gap: 16px;
}

.grid-auto-196 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(196px, 1fr));
    gap: 16px;
}

.grid-auto,
.grid-auto-256 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
    gap: 16px;
}

.grid-auto-320 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 16px;
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(256px, 1fr));
    grid-template-rows: repeat(auto-fit, min-content);
    gap: 16px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(256px, 1fr));
    gap: 16px;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(256px, 1fr));
    gap: 16px;
}

@import '../styles/spacing';

@for $i from 1 through 10 {
    .space-x-#{$i * 4} {
        @include space-x($i * 4);
    }

    .space-y-#{$i * 4} {
        @include space-y($i * 4);
    }
}
