@import 'colours';

&::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 7px;
    height: 7px;
}

&::-webkit-scrollbar-thumb {
    --scroll-accent: var(--side-nav-accent-rgb, var(--profile-header-accent-rgb, #{derive-rgb-values-from-hex($brand-purple)}));

    border-radius: 4px;
    background-color: rgba(black, .1);

    &:hover {
        background-color: rgba(var(--scroll-accent), .6);
    }
}

&:hover {
    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--scroll-accent), .4);
    }
}
