%icon-size-8 {
    line-height: 8px;
    font-size: 8px;
    width: 8px;
    height: 8px;
    min-height: 8px;
    min-width: 8px;

    vertical-align: middle;
}

%icon-size-12 {
    line-height: 12px;
    font-size: 12px;
    width: 12px;
    height: 12px;
    min-height: 12px;
    min-width: 12px;

    vertical-align: middle;
}

%icon-size-14 {
    line-height: 14px;
    font-size: 14px;
    width: 14px;
    height: 14px;
    min-height: 14px;
    min-width: 14px;

    vertical-align: middle;
}

%icon-size-16 {
    line-height: 16px !important;
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    min-height: 16px !important;
    min-width: 16px !important;

    vertical-align: middle;
}

%icon-size-18 {
    line-height: 18px;
    font-size: 18px;
    width: 18px;
    height: 18px;
    min-height: 18px;
    min-width: 18px;

    vertical-align: middle;
}

%icon-size-20 {
    line-height: 20px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;

    vertical-align: middle;
}

%icon-size-24 {
    line-height: 24px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;

    vertical-align: middle;
}

%icon-size-32 {
    line-height: 32px;
    font-size: 32px;
    width: 32px;
    height: 32px;
    min-height: 32px;
    min-width: 32px;

    vertical-align: middle;
}

%icon-size-48 {
    line-height: 48px;
    font-size: 48px;
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;

    vertical-align: middle;
}
