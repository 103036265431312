@import '../icons/icon-sizes';

$border-radius: 8px;
.mat-tab-group{
    position: relative;
}
.mat-tab-nav-bar.mat-tab-header,
.mat-tab-group .mat-tab-header {
    border: 0;

    .mat-tab-links,
    .mat-tab-labels {
        border-bottom: 1px solid rgba(black, .06);
    }

    .mat-tab-link,
    .mat-tab-label {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        padding: 0 16px;

        border: 1px solid transparent;
        border-bottom: 0;
        bottom: -1px;

        .mat-tab-label-content {
            gap: 8px;
        }

        &-active {
            background: darken($content-background, 3%);
            border-color: rgba(black, .06);
            opacity: 1;
        }

        .mat-icon:first-child {
            @extend %icon-size-16;
        }
    }

    .mat-tab-link {
        gap: 8px;
    }

    .mat-ink-bar {
        display: none;
    }
}

.mat-tab-nav-panel,
.mat-tab-group .mat-tab-body-wrapper {
    display: block;

    padding: 16px;

    background: darken($content-background, 3%);
    border: 1px solid rgba(black, .06);
    border-top: 0;
    border-bottom-left-radius: $border-radius;

    border-bottom-right-radius: $border-radius;
}
