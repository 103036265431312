h1, h2, h3, h4 {
    small {
        font-size: .7em;
    }
}

h2,
h3 {
    font-weight: normal;
}
