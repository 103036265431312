@use '@angular/material' as mat;

.mat-tooltip {
    &.tooltip-overflow {
        overflow-wrap: anywhere;
    }

    &.tooltip-multiline {
        white-space: pre;
        overflow-wrap: anywhere;
    }

    &.tooltip-white {
        background: white;
        color: black;
        font-size: 14px;

        @include mat.elevation(1);
    }
}
